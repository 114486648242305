/* ---------------------------------------------------
    TOP BAR STYLE
----------------------------------------------------- */

.topbar {
    /*color: #0597f2;*/
    width: 100%;
    justify-content: center !important;
}

.jupiter-topbar {
    background-color: #eb9534;
}

.mercury-topbar {
    background-color: darkgray;
}

.venus-topbar {
    background-color: #5ae661
}

/*.brand:hover {
    color: #0597f2;
}*/

@media screen and (max-width: 600px) {
    .container-fluid {
        justify-content: center !important;
    }

    .topbar {
        /*color: #0597f2;*/
        width: 100%;
        justify-content: center !important;
        margin-top: 0px;
    }
}

@media screen and (max-width: 479px) {
    .container-fluid {
        justify-content: center !important;
    }

    .topbar {
        /*color: #0597f2;*/
        width: 100%;
        justify-content: center !important;
        margin-top: 0px;
    }
}

.brand {
    font-size: 34px !important;
    font-weight: bolder;
    cursor: pointer;
    display: flex;
}

.brand-name {
    margin: 0px 0px -10px 13px;
}

.brand-subtext {
    font-size: 14px !important;
    margin: 0px 0px 0px 0px;
}

.sidebar-button {
    color: #fff;
    background-color: #0597f2 !important;
}

.topbar-item {
    color: black;
}

.content {
    height: 100vh;
    width: 100%;
}