/* ---------------------------------------------------
    SYSTEM PRINT SETTINGS
----------------------------------------------------- */


@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    #print {
        display: none;
    }
}

@page {
    size: auto;
    margin: 3mm;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


/* ---------------------------------------------------
     GENERAL APP STYLE
----------------------------------------------------- */
.prevent-text-selection {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.app-header {
    display: flex !important;
    justify-content: space-between !important;
    margin: 20px 25px 20px 25px;
}

.app-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;
    margin: 40px 50px 40px 50px;
    justify-content: left;
    background: linear-gradient(90deg, #3597ff, #29c1fe);
    padding: 20px;
    border-radius: 10px;
}

.app-container-label {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #243c5e;
    cursor: default;
    border-radius: 5px;
    padding: 5px 5px 5px 20px;
}

.app-app-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    width: 100%;
    padding: 0px 20px 0px 20px;
}

.app-app-subcontainer {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    width: 100%;
    padding: 10px 20px 10px 20px;
    border-radius: 5px;
    overflow: auto;
    
}

.app-recent-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    column-gap: 20px;
    padding: 10px 20px 10px 20px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: auto
}

.app-recent-subcontainer {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    
    
    border-radius: 5px;
}

.app-form-label {
    font-size: 14px;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .app-container {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        margin: 40px 50px 40px 50px;
        justify-content: center;
    }
}

@media screen and (max-width: 479px) {
    .app-container {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        margin: 40px 50px 40px 50px;
        justify-content: center;
    }
}

.app {
    width: 120px;
    height: 120px;
    border: 3px solid black;
    background-color: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-radius: 10px;
    cursor: pointer;
    min-width: 120px;
}

.appIcon {
    display: flex;
    height: 50%;
    width: 50%;
    margin: 0 auto;
}

.app-icon-double {
    height: 50%;
    display: flex;
    margin: 0 auto;
}

.close-btn-container {
    position: absolute;
    /*margin-right: 20px;*/
    font-weight: 900;
    right: 20px;
}

.icon {
    height: 50%;
    margin: 0 auto;
    margin-top: 20px;
}

.appName {
    color: black;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 5px;
    text-align: center;
    width: 100%;
}

.item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 25px 30px 25px;
    justify-content: left;
}

@media screen and (max-width: 479px) {
    .item-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
}

.dropdown-item {
    font-weight: bold !important;
    text-align: center !important;
}

.loading-animation-container {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: white;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.app-main-container {

}

/* ---------------------------------------------------
    APP FOLDER STYLE
----------------------------------------------------- */

.app-folder-container {
    display: flex;
    flex-direction: column;
}

.app-folder-item-title {
    margin-top: 20px;
    text-align: center;
}

.app-folder-item-text {
    margin-top: 3px;
    text-align: center;
}

.app-folder-row {
    display: flex;
}

.app-folder-item {
    border: 2px solid gray;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    max-height: 75px;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-bottom: 10px;
}

.app-folder-item-download {
    border: 2px solid gray;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    max-height: 75px;
    margin: 10px 10px 20px 10px;
    width: 8%;
    background-color: lightgreen;
}

.icon-download {
    width: 50%; 
}

.app-folder-item-subgroup {
    display: flex;
}

.app-folder-item-icon {
    margin-top: -10px;
    max-width: 100px;
}

.app-folder-item-icon-download {
    margin-top: -10px;
    max-width: 80px;
}

.app-folder-item-filetype-pdf {
    background-color: red;
}

.app-folder-item-filetype-xlsx {
    background-color: green;
}

@media screen and (max-width: 479px) {
    .app-folder-item-title {
        margin-top: 0px;
        text-align: center;
        overflow: hidden;
        word-wrap: break-word;
        width: 100px;
    }
}

@media screen and (max-width: 500px) {
    .app-folder-item-title {
        margin-top: 0px;
        text-align: center;
        overflow: hidden;
        word-wrap: break-word;
        min-width: 100px;
        max-width: 150px;
    }
}

/* ---------------------------------------------------
    JUPITER STYLE
----------------------------------------------------- */
.jupiter-app-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
    margin: 40px 50px 40px 50px;
    justify-content: flex-start;
    background: linear-gradient(90deg, #3597ff, #29c1fe);
    padding: 20px;
    border-radius: 10px;
}

.jupiter-folders-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 15px;
    margin: 25px 30px 25px 30px;
    justify-content: flex-start;
}

body > div:nth-child(3) > div > div.modal.fade.show {
    overflow-x: auto !important;
}

.jupiter-folder-modal {
    min-width: 905px;
}

.jupiter-folder {
    width: 150px;
    height: 150px;
    padding-bottom: 5px;
}

.jupiter-folder-title {
    font-weight: bold;
    text-align: center;
    max-height: 50px;
    min-height: 50px;
}

.jupiter-folder-body {
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    
}

.jupiter-folder-icon {
    margin-top: 10px;
}

.jupiter-open-folder-btn {
    font-weight: bold !important;
    width: 100px;
    margin: auto;
}

.jupiter-modal-header {
    display: flex;
    justify-content: space-between;
}

.jupiter-folder-file-label {
    font-size: 20px;
    font-weight: bold;
}

.jupiter-files-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin: 25px 30px 25px 30px;
    justify-content: left;
}

.jupiter-file {
    width: 150px;
    height: 150px;
    padding-bottom: 5px;
    min-width: 150px !important;
}

.jupiter-file-title {
    text-overflow: ellipsis;
    text-align: center;
    font-size: 12px;
    height: 36px;
    overflow: hidden;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.jupiter-file-popup-label {
    font-weight: bold;
    margin-bottom: 0px;
}

.jupiter-no-item-text {
    width: 100%;
    margin-top: 50px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: lightgray;
}

.jupiter-file-popover-footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}

.jupiter-delete-file-btn {
    
}

#deleteFolderPromptTitle {
    justify-content: space-evenly !important;
}

#deleteFolderPromptTitle > h5 {
    margin-bottom: -20px;
}

.jupiter-delete-confirmation {
    width: 50px !important;
    text-align: center;
}

#deleteInput0 {
    color: blue;
}

#deleteInput1 {
    color: blue;
}

#deleteInput2 {
    color: blue;
}

.jupiter-delete-confirmation::placeholder {
    color: red;
}

.jupiter-file-view-title {
    width: -webkit-fill-available;
    display: flex;
}

.jupiter-open-file-info {
    position: absolute;
    right: 0;
    margin-right: 5%;
}

#jupiterFileViewHeader > div .modal-header {
    /*display: flex;*/
    justify-content: space-evenly;
    /*width: 100%;*/
    height: 50px;
}

#jupiterFileViewHeader > h5 {
    /*width: 90%;*/
    /*display: flex;*/
}

/* Jupiter iframe */
.jupiter-iframe-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}

.jupiter-iframe {
    flex-grow: 1;
    border: none;
}

/* Horizontal */
@media screen and (max-width: 800px) {
    .jupiter-folder-modal {
        min-width: 600px;
    }

    .jupiter-folders-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
        margin: 25px 15px 25px 15px;
        justify-content: flex-start;
    }

    .jupiter-files-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
        margin: 25px 15px 25px 15px;
        justify-content: flex-start;
    }
}

/* Vertical */
@media screen and (max-width: 479px) {
    .jupiter-app-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 15px;
        margin: 40px 50px 40px 50px;
        justify-content: center;
        background: linear-gradient(90deg, #3597ff, #29c1fe);
        padding: 20px;
        border-radius: 10px;
    }

    .jupiter-folder-modal {
        min-width: 400px;
    }

    .jupiter-folders-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
        margin: 25px 15px 25px 15px;
        justify-content: flex-start;
    }

    .jupiter-files-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
        margin: 25px 15px 25px 15px;
        justify-content: flex-start;
    }
}

/* ---------------------------------------------------
    MERCURY STYLE
----------------------------------------------------- */
.mercury-app-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
    margin: 40px 50px 40px 50px;
    justify-content: flex-start;
    background: linear-gradient(90deg, #3597ff, #29c1fe);
    padding: 20px;
    border-radius: 10px;
}

.mercury-app-container-item {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.mercury-app-header {
    display: flex !important;
    justify-content: space-between !important;
    margin: 20px 25px 20px 25px;
}

.mercury-name {
    color: white;
    font-size: 90%;
    font-weight: bold;
    background-color: #0597f2;
    border-radius: 10px;
    margin-left: 10%;
    margin-right: 10%;
    padding: 0px 22px;
    overflow-wrap: break-word;
    hyphens: auto;
}

/* Reports */
.mercury-report-input-containers {
    display: flex;
    justify-content: space-evenly;
}

.mercury-report-input-controls {
    width: 50%;
    display: flex;
}

.mercury-report-input-labels {
    font-weight: bold;
    width: 100%;
    text-align: right;
}

.mercury-report-input-label {
    width: 50%;
    margin-top: 5px;
}

.mercury-report-input-label-units {
    margin-left: 5px;
    margin-top: 5px;
}

.mercury-report-inputs {
    display: flex;
    height: 40px;
    margin-bottom: 5px;
}

.mercury-report-inputs-accordion {
    margin-top: 10px;
}

.mercury-report-input-dropdown {
    text-align: center;
    width: 100px !important;
    cursor: pointer;
    border: 3px solid lightgray !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    margin-left: 5px;
}

.mercury-report-input-number {
    text-align: center;
    width: 100px;
    cursor: pointer;
    border: 3px solid lightgray;
    border-radius: 5px;
    font-weight: bold;
    margin-left: 5px;
}

.mercury-report-info-table {
    text-align: right;
    font-size: 16px;
    font-weight: 500;
}

.mercury-report-result-table-header {
    text-align: center;
    vertical-align: middle;
    font-size: 14px
}

.mercury-report-result-table-item {
    text-align: center;
    vertical-align: middle;
}

.mercury-top-container {
    display: flex;
    justify-content: space-between;
}

.mercury-btn-containers {
    display: flex;
    flex-direction: row;
    justify-content: right;
}

.mercury-download-icon {
    margin-top: 20px;
    height: 42%;
    max-width: 100px;
    max-height: 100px;
    cursor: pointer;
}

@-moz-document url-prefix() {
    .mercury-download-icon {
        margin: 20px 30px 0px 30px
    }
}

.mercury-download-icon-container {

}

.mercury-venue-name {
    color: black;
    font-size: 22px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 20px;
}

.mercury-report-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin-bottom: 12px;
    overflow-wrap: anywhere;
}

.mercury-report-close-btn-container {
    margin: 15px;
}

.mercury-filters-container {
    background-color: darkgray;
    border-radius: 10px;
    height: 100px;
    margin: auto;
    margin: 10px 15px 10px 15px;
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
}

.mercury-ddl-btn-container {
    margin: auto;
}

.mercury-ddl-btn {
    width: 100px;
    max-width: 100px;
}

.mercury-selected-data {
    margin: 20px 20px 20px 20px;
    width: 30%;
    background-color: white;
    border-radius: 10px;
}

.mercury-selected-data-name {
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: center
}

.mercury-general-info-container {
    margin: 10px 15px 10px 15px;
}

.mercury-empty-folder-text {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: lightgray;
}

.mercury-general-header {
    --bs-accordion-btn-bg: darkgray;
    --bs-accordion-active-bg: darkgray;
}

.mercury-general-data-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.mercury-data-table-container {
    text-align: center;
}

.mercury-no-filter-selected {
    margin-top: 50px;
    text-align: center;
    color: lightgray;

    font-size: 18px;
    font-weight: bold;
}

.mercury-pdf-container {
    margin: auto;
    max-width: 1000px;
}

.mercury-pdf-doc {

}

.mercury-pdf-button-container {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-evenly;
    color: lightgray;
    font-weight: bold;
}

.mercury-loading-animation-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;
    border: 1px solid black;
    border-radius: 10px;
    background-color: white;
    padding: 7px;
}

/* ---------------------------------------------------
    VENUS STYLE
----------------------------------------------------- */
.venus-app-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    margin: 40px 50px 40px 50px;
    justify-content: flex-start;
    flex-direction: row;
    background: linear-gradient(90deg, #3597ff, #29c1fe);
    padding: 20px;
    border-radius: 10px;
}

.venus-app-container-item {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.venus-app-header {
    display: flex !important;
    justify-content:space-between !important;
    margin: 20px 25px 20px 25px;
}

.venus-datepicker {
    width: 90px;
    cursor: pointer;
    user-select: none !important;
    border: 0;
    caret-color: transparent;
}

.modal .venus-timesheet-modal {
    min-width: 900px;
    /*overflow-x: hidden !important;*/
}

body > div:nth-child(5) > div > div.modal.fade.show {
    overflow-x: auto !important;
}

body > div:nth-child(4) > div > div.modal.fade.show {
    overflow-x: auto !important;
}

.venus-timesheet {
    table-layout: fixed;
}

.venus-timesheet-header {
    text-align: center;
}

.venus-timesheet-date-cell {
    display: flex;
    flex-direction: column;
}

.venus-timesheet-date-cell-today {
    border-width: 5px !important;
    border-color: royalblue;
    background-color: dodgerblue !important;
}

.venus-timesheet-date-cell-item {
    display: flex;
    flex-direction: column;
}

.venus-timesheet-total-hours {
    font-size: 24px;
    font-weight: bold;
}

.venus-timesheet-hours-input {
    text-align: center;
    width: 30px;
    cursor: pointer;
    border: 0px;
    border: 3px solid lightgray;
    /*background-color: lightgray;*/
    border-radius: 5px;
    font-weight: bold;
}

input[type="number"].venus-timesheet-hours-input::-webkit-inner-spin-button,
input[type="number"].venus-timesheet-hours-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

.venus-timesheet-note-input {
    margin-top: 5px;
    width: 100%;
    resize: none;
    border-color: lightgray !important;
    border: hidden;
    border-radius: 3px;
    outline: none;
    overflow: hidden;
    font-size: 12px;
}

.venus-timesheet-date-cell-circle {
    width: 22px;
    /*line-height: 20px;*/
    border-radius: 50%;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: lightgray;
    margin-bottom: 20px;
}

.venus-timesheet-blank-date-cell {
    background-color: lightgray !important;
}

.venus-timesheet-approved {
    color: green;
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.venus-timesheet-approved-list {
    color: green;
    margin-right: 5px;
}

.venus-timesheet-checkbox {
    margin-top: 10px;
    width: 20px;
    height: 20px;
    vertical-align: central;
}

.venus-quote-modal-header {
    display: flex;
    justify-content: space-between;
    margin: 0px 15px 10px 15px !important;
}

#venusQuote {
    padding: 15px;
}

.venus-quote-no-quotes {
    margin-top: 50px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: lightgray;
}

.venus-quote-list-user {
    font-size: 16px;
    font-weight: bold;
    margin: 0px 20px 0px 20px;
}

.venus-quote-list-item {
    font-size: 16px !important;
    font-weight: bold !important;
    display: flex !important;
    justify-content: space-between !important;
}

.venus-quote-client-info-table {
    text-align: right;
    font-size: 12px;
    font-weight: 500;
}

.venus-quote-client-info-table input {
    border: 0;
}

.venus-quote-client-info-table input:focus {
    outline: none;
}

.venus-quote-workitem-input {
    width: 100%;
    border: 0;
    font-size: 12px;
}

.venus-quote-workitem input:focus {
    outline: none;
}

.venus-quote-workitem textarea:focus {
    outline: none;
}

@media screen and (max-height: 479px) {
    .venus-app-container-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;
    }
}

.react-pdf__Document {
    height: 575px;
}

.react-pdf__Page {
    overflow: hidden !important;
}

@media screen and (max-width: 479px) {
    .react-pdf__Document {
        height: 250px;
    }
}

@media screen and (max-height: 479px) {
    .react-pdf__Document {
        height: 475px;
    }
}

.accordion-button {
    font-size: 20px;
    font-weight: bold;
}

.accordion-button:not(.collapsed) {
    color: lightgray;
    background-color: lightgray;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

