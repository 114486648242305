

.jupiter {
    background-color: #faa54b;
    color: darkgray;
}

.mercury {
    background-color: antiquewhite;
    color: darkgray;
}

.app-navbar {
    color: black;
}

.app-logo {
    cursor: pointer;
}


.app-brand {
    color: #0597f2;
    font-size: 34px !important;
    font-weight: bolder;
    display: flex;

    cursor: default;
}

.app-brand-name {
    margin: 0px 0px -10px 13px;
}

.mercury-brand-subtext {
    font-size: 14px !important;
    margin: 0px 0px 0px 57px;
}
.jupiter-brand-subtext {
    font-size: 14px !important;
    margin: 0px 0px 0px 42px;
}

.venus-brand-subtext {
    font-size: 18px !important;
    margin: 0px 0px 0px 40px;
}

.app-navbar-items {
    display: flex;
    justify-content: end;
    margin-right: 25px;
    align-items: center;
    color: black;
}

.app-navbar-item {
    border-right: 3px inset black;
}

.dropdown {
    color: black !important;
    background-color: transparent !important;
    border: none !important;
}

.topbar-buttons {
    display: flex;
}

.topbar-sidebar-toggle-btn {
    --bs-text-opacity: 1;
    --bs-btn-active-bg: inherit !important;
    --bs-btn-hover-bg: transpartent !important;
    border: 0 !important;
    color: inherit !important;
    background-color: transparent !important;
}

