/* ---------------------------------------------------
    DRAG AND DROP STYLE
----------------------------------------------------- */
.file-container {
    
}

.targetbox {
    
}

.upload-container {
    
}

.upload-button {
    font-weight: bolder;
}

.upload-btn {
    width: 150px;
    margin: auto;

}

.upload-dropbox {
    border: 3px dashed black;
    border-radius: 25px 25px 25px 25px;
    text-align: center;
    aspect-ratio: 4 / 3;
}

.upload-dropbox-file-over {
    border: 3px dashed green;
    border-radius: 25px 25px 25px 25px;
    text-align: center;
    aspect-ratio: 4 / 3;
}

.upload-dropbox-text {
    margin: 35%;
    /*position: absolute;*/
    top: 50%;

    font-weight: bold;
    color: darkgrey;
    
}

.upload-complete {
    text-align: center;
    font-weight: bold;
}

@media screen and (max-width: 479px) {
    .modal-content {
        /*margin-top: 45%;*/
    }
}
/* ---------------------------------------------------
    FOLDER STYLE
----------------------------------------------------- */
.recent-files {
    margin: 10px 15px 10px 15px;
}

.seperator {
    margin: 10px 10px;
    color: black;
}

.parent-folder {
    margin: 10px 15px 10px 15px;
}

.child-folder {
    margin-bottom: 10px;
}

.cci-folder-header {
    --bs-accordion-btn-bg: purple;
    --bs-accordion-active-bg: purple;
    font-size: 20px;
}

.folder-btn-container {
    display: flex;

}

.folder-btn {
    margin: 5px;
    width:  150px;
}

.reports-btn {
    width: 150px;
}

/* ---------------------------------------------------
    FILE STYLE
----------------------------------------------------- */
.folder-content {
    display: flex;
    flex-direction: row;
}

.file-style {
    width: 200px;
    margin: 5px;
}

/* use reactstrap card modals; find out a way to populate modal fields */